import { NexusIcon } from '@nexus/react';
import AccordionComponent from 'app/components/nds/accordion/accordion';
import { useEffect, useState } from 'react';
import DownIcon from '@nexus/core/dist/assets/icons/action/ic_expand_more_24px.svg';
import UpIcon from '@nexus/core/dist/assets/icons/action/ic_expand_less_24px.svg';
import DownloadIcon from '@nexus/core/dist/assets/icons/action/ic_download_24px.svg';
import ResetIcon from '@nexus/core/dist/assets/icons/media/ic_replay_24px.svg';
import DatePicker from 'react-date-picker';
import CalendarIcon from '@nexus/core/dist/assets/icons/media/ic_today_24px.svg';
import Select from 'app/components/nds/select/select';
import { createFileFromResponse, formatDateSelected } from 'services/services';
import { useAppDispatch } from 'store';
import {
    dataSourceTemplateInfo,
    getTestSessionDataSourceTemplate,
    showToastMessage,
    updateCurrentTestSession,
    updateTestSession,
    uploadDataSourceTemplate,
} from '../../redux/testSessionReducer';
import { DateFormat, fileStatusFlag, SessionStatus } from 'constants/constants';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currentTestSession } from '../../redux/testSessionSelectors';
import CustomFileUpload from 'app/components/fileUpload/customFileUpload';

const AccordionDataSelection = ({ dataSource }: { dataSource: any }) => {
    const dispatch = useAppDispatch();
    const [intervalID, setIntervalID] = useState<any>(undefined);
    const [removeUpload, setRemoveUpload] = useState<boolean>(false);
    const [showAccordion, setShowAccordion] = useState<boolean>(dataSource?.showAccordion || false);
    const [t] = useTranslation('lang');
    const testSession = useSelector(currentTestSession);
    const { dataSources, testSessionTestCases, testSessionId, testSessionIsDraft } = testSession;
    const { id, name, uploadedFile, dateTo, dateFrom, filterBy, filterableColumns } = dataSource;
    const [dateRangeField, setDateRangeField] = useState<string>(filterBy || t('dataSelection.pleaseSelect'));
    const filterableCols = filterableColumns?.date_range
        ? filterableColumns?.date_range.map((val: any) => {
              return {
                  label: val?.title,
                  value: val?.name,
              };
          })
        : [];

    useEffect(() => {
        return () => {
            intervalID !== undefined && clearInterval(intervalID);
        };
    }, [intervalID]);

    useEffect(() => {
        if (uploadedFile?.status === fileStatusFlag.ready) {
            dispatch(
                showToastMessage({
                    autoClose: 5000,
                    id: 'fileUploadValidation_ready',
                    message: 'Data Validation Complete',
                    type: 'success',
                }),
            );
        }
        if ([fileStatusFlag.invalid, fileStatusFlag.ready].includes(uploadedFile?.status)) {
            clearInterval(intervalID);
        }
    }, [uploadedFile?.status]);

    useEffect(() => {
        if (uploadedFile?.id && ![fileStatusFlag.invalid, fileStatusFlag.ready].includes(uploadedFile?.status)) {
            triggerTestSessionUpdate(uploadedFile.id);
        }
        // else {
        //     uploadedFile?.id && dispatch(dataSourceTemplateInfo(uploadedFile.id));
        // }
    }, [uploadedFile?.id]);

    //UPLOAD POST CALL
    const triggerTestSessionUpdate = (dataFileId: any) => {
        // const updatedTestSessionTestCases = testSessionTestCases.map((item: any) => {
        //     return {
        //         ...item,
        //         testCaseParameters: JSON.stringify({
        //             ...item?.testCaseParameters,
        //             execution_parameters: item?.scriptConfiguration?.execution_parameters,
        //             filter_by: dateRangeField === t('dataSelection.pleaseSelect') ? null : dateRangeField,
        //         }),
        //     };
        // });
        const payload = {
            testSessionRequest: {
                dataInputFileId: dataFileId,
                draftStatus: SessionStatus.step4,
                isDraft: testSessionIsDraft !== undefined ? testSessionIsDraft : true,
                testSessionTestCase: testSessionTestCases,
            },
            id: testSessionId,
        };

        // PATCH CALL TO COMMENCE DQ CHECK
        dispatch(updateTestSession(payload)).then((data: any) => {
            if (data && data.payload) {
                pollUploadedFileStatus(dataFileId);
            }
        });
    };
    //POLLING API
    const pollUploadedFileStatus = (dataFileId: any) => {
        let count = 0;
        const id = setInterval(() => {
            ++count;
            if (count >= 60) {
                intervalID !== undefined && clearInterval(intervalID);
                return;
            }
            // To do- Need to remove after validation
            dispatch(dataSourceTemplateInfo(dataFileId));
        }, 5000);
        setIntervalID(id);
    };

    const downloadTemplate = () => {
        dispatch(getTestSessionDataSourceTemplate(id))?.then((response: any) => {
            if (response && response.payload && response.payload.status >= 200 && response.payload.status < 300) {
                createFileFromResponse(response);
            }
        });
    };

    const handleUpload = (queue: any) => {
        const payload = {
            file: queue[0],
            id: id,
        };

        dispatch(uploadDataSourceTemplate(payload)).then((response: any) => {
            if (response && response.payload && response.payload.status >= 200 && response.payload.status < 300) {
                dispatch(
                    showToastMessage({
                        autoClose: 7000,
                        id: 'fileUploadValidation_uploaded_session',
                        message: 'Data validation in progress, you will be notified when document is ready',
                        type: 'info',
                    }),
                );
            }
        });
    };
    const updateDateFields = (date: any, type: string) => {
        const updatedDataSources = dataSources.map((ds: any) => {
            if (ds.id === id) {
                return { ...ds, [type]: formatDateSelected(date) };
            }
            return ds;
        });
        dispatch(updateCurrentTestSession({ dataSources: updatedDataSources }));
    };

    const handleDropDown = (value: string) => {
        setDateRangeField(value);
        const updatedDataSources = dataSources.map((ds: any) => {
            if (ds.id === id) {
                return { ...ds, filterBy: value };
            }
            return ds;
        });
        dispatch(updateCurrentTestSession({ dataSources: updatedDataSources }));
    };

    const resetUpload = () => {
        setRemoveUpload(true);
        const updatedDataSources = dataSources.map((ds: any) => {
            if (ds.id === id) {
                const { uploadedFile, ...rest } = ds; // eslint-disable-line @typescript-eslint/no-unused-vars
                return rest;
            }
            return ds;
        });
        dispatch(updateCurrentTestSession({ dataSources: updatedDataSources }));
    };

    const resetDate = () => {
        const updatedDataSources = dataSources.map((ds: any) => {
            if (ds.id === id) {
                return { ...ds, dateFrom: null, dateTo: null, filterBy: null };
            }
            return ds;
        });
        dispatch(updateCurrentTestSession({ dataSources: updatedDataSources }));
        setDateRangeField(t('dataSelection.pleaseSelect'));
    };

    return (
        <AccordionComponent
            size='xl'
            accordionContent={{
                isOpen: showAccordion,
                title: `Data Source: ${name}`,
            }}
            handleToggle={() => setShowAccordion(!showAccordion)}
            icon={showAccordion ? { icon: DownIcon, iconSize: 'md' } : { icon: UpIcon, iconSize: 'md' }}
            showIcon={true}
            iconStyle='align-accordion-icon'
        >
            <div className='accordion-data-selection-container'>
                <div className='download-text nexus-mb-1'>
                    <span className='blue-text' onClick={downloadTemplate}>
                        <NexusIcon className='nexus-mt-1 nexus-ml-1' src={DownloadIcon} size='sm' />
                        {t('buttons.download')}{' '}
                    </span>{' '}
                    {t('dataSelection.downloadText')}
                </div>
                <div className='file-date-container'>
                    <div className='upload-file-container'>
                        <div className='nexus-row align-content'>
                            <div className='title nexus-mb-1'>{t('dataSelection.uploadText')}</div>
                            <div className='reset' data-testid='reset' onClick={resetUpload}>
                                <NexusIcon className='nexus-mt-2' src={ResetIcon} size='sm' />
                                {t('dataSelection.resetToDefault')}
                            </div>
                        </div>
                        <div className='title-text nexus-mb-1'>{t('dataSelection.uploadMsg')} </div>
                        <CustomFileUpload
                            handleUpload={handleUpload}
                            removeUpload={removeUpload}
                            // disabled={dateRangeField ? true : false}
                        />
                        <div className='nexus-row align-content info-text nexus-mt-1'>
                            <div>
                                <span className='bold-text'>{t('dataSelection.formats')}: </span>
                                {t('dataSelection.fileTypes')}
                            </div>
                            <div>
                                <span className='bold-text'>{t('dataSelection.maxSize')}: </span>
                                {t('dataSelection.size')}
                            </div>
                        </div>
                    </div>

                    <div className='date-range-container'>
                        <div className='nexus-row align-content'>
                            <div className='title nexus-mb-1'>{t('dataSelection.header2')}</div>
                            <div className='reset' data-testid='reset' onClick={resetDate}>
                                <NexusIcon className='nexus-mt-2' src={ResetIcon} size='sm' />
                                {t('dataSelection.resetToDefault')}
                            </div>
                        </div>

                        <div className='title-text nexus-mb-1'>{t('dataSelection.subHeader3')}</div>

                        <div className='nexus-row nexus-mb-1'>
                            <div className='nexus-col-lg-6 nexus-mt-1 nexus-mb-1'>
                                <div className='label-date-from' data-testid='label-date-from'>
                                    {t('dataSelection.dateFrom')}
                                </div>
                                <DatePicker
                                    data-testid='input-textbox-date-from'
                                    format={process.env.REACT_APP_DATE_FORMAT}
                                    className='date-picker nexus-mt-1 nexus-mb-1'
                                    value={dateFrom}
                                    onChange={(date: any) => {
                                        updateDateFields(date, 'dateFrom');
                                    }}
                                    disabled={uploadedFile?.id || dateRangeField === t('dataSelection.pleaseSelect')}
                                    monthPlaceholder={DateFormat.monthPlaceholder}
                                    dayPlaceholder={DateFormat.dayPlaceholder}
                                    yearPlaceholder={DateFormat.yearPlaceholder}
                                    maxDate={dateTo ? new Date(dateTo) : undefined}
                                    calendarIcon={<NexusIcon src={CalendarIcon} size='sm' />}
                                    nativeInputAriaLabel='date-test-from'
                                />
                            </div>
                            <div className='nexus-col-lg-6 nexus-mt-1 nexus-mb-1'>
                                <div className='label-date-to' data-testid='label-date-to'>
                                    {t('dataSelection.dateTo')}
                                </div>
                                <DatePicker
                                    data-testid='input-textbox-date-to'
                                    format={process.env.REACT_APP_DATE_FORMAT}
                                    className='date-picker  nexus-mt-1 nexus-mb-1'
                                    calendarIcon={<NexusIcon src={CalendarIcon} size='sm' />}
                                    value={dateTo}
                                    onChange={(date: any) => {
                                        updateDateFields(date, 'dateTo');
                                    }}
                                    disabled={uploadedFile?.id || dateRangeField === t('dataSelection.pleaseSelect')}
                                    monthPlaceholder={DateFormat.monthPlaceholder}
                                    dayPlaceholder={DateFormat.dayPlaceholder}
                                    yearPlaceholder={DateFormat.yearPlaceholder}
                                    minDate={dateFrom ? new Date(dateFrom) : undefined}
                                    nativeInputAriaLabel='date-test-to'
                                />
                            </div>
                        </div>
                        <div className=''>
                            <div className='label-select  nexus-mt-1 nexus-mb-1'>{t('dataSelection.subHeader4')}</div>
                            <Select
                                key={
                                    dateRangeField === t('dataSelection.pleaseSelect') && filterableCols[0]?.value
                                        ? 'dateRangeField'
                                        : dateRangeField
                                }
                                disabled={uploadedFile?.id ? true : false}
                                options={[...filterableCols]}
                                customOnChange={handleDropDown}
                                selectedValue={dateRangeField || t('dataSelection.pleaseSelect')}
                                disabledOption={t('dataSelection.pleaseSelect')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AccordionComponent>
    );
};

export default AccordionDataSelection;
