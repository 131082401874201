import './expressionBuilder.scss';

import { EXPRESSION, MVEL, SessionStatus, VALID } from 'constants/constants';
import { useAppDispatch } from 'store';
import React, { useEffect } from 'react';
import { stepperRoutes } from 'constants/stepperConstants';
import { useTranslation } from 'react-i18next';
import AccordionExpressionBuilder from './accordionExpressionBuilder';
import {
    getCanonicalModelById,
    getExpPreview,
    updateTestSession,
    updateActiveStep,
} from '../../redux/testSessionReducer';
import { currentTestSession } from '../../redux/testSessionSelectors';
import { useSelector } from 'react-redux';

const ExpressionBuilder: React.FC = () => {
    const [t] = useTranslation('lang');
    const dispatch = useAppDispatch();
    const testSession = useSelector(currentTestSession);
    const {
        testSessionId,
        dateFrom,
        dateTo,
        testSessionIsDraft,
        testSessionTestCases,
        uploadedDataSourceData,
        canonicalModelData,
    } = testSession || {};
    const uploadedDataSourceDataId = uploadedDataSourceData?.id;
    const canonicalModelId = testSessionTestCases?.[0]?.canonicalModelId;

    // get columns info from cannonical model
    useEffect(() => {
        if (canonicalModelId && (!canonicalModelData || Object.keys(canonicalModelData).length === 0)) {
            dispatch(getCanonicalModelById(canonicalModelId));
        }
    }, []);

    const handleBackButton = () => {
        dispatch(updateActiveStep(stepperRoutes.step_4));
    };

    const handleNextButton = async () => {
        const updatedTestSessionTestCase = testSessionTestCases?.map((testCase: any) => {
            const { scriptConfiguration, testCaseParameters } = testCase;
            const { filter_by, execution_parameters: test_execution_parameters } = testCaseParameters || {};
            const { execution_parameters } = scriptConfiguration;
            return {
                testCaseId: testCase.id || testCase.testCaseId,
                testCaseParameters: {
                    filter_by,
                    execution_parameters: test_execution_parameters || execution_parameters,
                },
            };
        });
        const payload = {
            testSessionRequest: {
                dataInputFileId: uploadedDataSourceDataId || null,
                dateFrom: dateFrom || null,
                dateTo: dateTo || null,
                draftStatus: SessionStatus.step6,
                isDraft: testSessionIsDraft ?? true,
                testSessionTestCases: updatedTestSessionTestCase,
            },
            id: testSessionId,
        };
        let valid = true;
        for (const testCase of testSessionTestCases) {
            if (testCase.scriptConfiguration?.type === MVEL) continue;
            if (testCase.testCasePreview !== '') continue;
            let response: any = await getPreviewExpression(testCase);
            if (response?.payload?.previewStatus !== VALID) {
                valid = false;
            }
        }
        if (!valid) return;
        dispatch(updateTestSession(payload)).then((data: any) => {
            if (data && data?.payload?.status >= 200 && data?.payload?.status < 300) {
                dispatch(updateActiveStep(stepperRoutes.step_6));
            }
        });

        // mvel type expression does not need validation
    };

    const getPreviewExpression = (testCase: any) => {
        const { scriptConfiguration, testCaseParameters } = testCase;
        let executionParameters;
        if (
            testCaseParameters?.execution_parameters &&
            Object.keys(testCaseParameters?.execution_parameters).length > 0
        ) {
            executionParameters = testCaseParameters?.execution_parameters;
        } else {
            executionParameters = testCaseParameters;
        }
        const payload = {
            canonicalModelId: testCase?.canonicalModelId,
            scriptConfiguration: {
                execution_parameters: executionParameters,
                expression_object: scriptConfiguration?.expression_object,
                type: EXPRESSION,
                user_selected_columns: scriptConfiguration?.user_selected_columns,
            },
        };
        return dispatch(getExpPreview({ testTemplatePreviewRequest: payload, testCaseId: testCase?.testCaseId }));
    };
    return (
        <div data-testid='expression-builder'>
            {testSessionTestCases?.map((testCase: any, index: number) => {
                const updatedTestCase = { ...testCase, showAccordion: index === 0 };
                return <AccordionExpressionBuilder key={testCase?.testCaseId} testCase={updatedTestCase} />;
            })}

            <div className='nexus-flex-row-reverse nexus-mt-3'>
                <div className='nexus-row'>
                    <button
                        type='button'
                        onClick={handleBackButton}
                        data-testid='expression-builder-back'
                        className={`nexus-ml-4 nexus-btn nexus-btn nexus-rhythm-top-5`}
                    >
                        {t('buttons.back')}
                    </button>
                    <button
                        type='submit'
                        onClick={handleNextButton}
                        data-testid='expression-builder-next'
                        className={`nexus-ml-4 nexus-btn nexus-btn-primary nexus-rhythm-top-5 nexus-mx-3`}
                        disabled={false}
                    >
                        {t('buttons.next')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ExpressionBuilder;
