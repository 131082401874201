import ButtonComponent from 'app/components/nds/button/button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { updateActiveStep, getAllCanonicalModels, updateTestSession } from '../../redux/testSessionReducer';
import { stepperRoutes } from 'constants/stepperConstants';
import { NexusCard, NexusIcon } from '@nexus/react';
import React, { useEffect } from 'react';
import './mapping.scss';
import RightArrow from '@nexus/core/dist/assets/icons/action/ic_arrow_right_alt_24px.svg';
import Select from 'app/components/nds/select/select';
import { getDropdownValues } from 'services/services';
import { currentTestSession, canonicalModels } from '../../redux/testSessionSelectors';
import { useSelector } from 'react-redux';

const Mapping: React.FC = () => {
    const [t] = useTranslation('lang');
    const dispatch = useAppDispatch();
    const testSession = useSelector(currentTestSession);
    const {
        dataSources: selectedDataSources,
        testSessionId,
        testSessionName,
        testSessionDescription,
        testSessionTestCases,
        filterInfo,
    } = testSession || {};
    const canonicalModelsData = useSelector(canonicalModels);
    const [dataSources, setDataSources] = React.useState<any>([]);

    const fetchCanonicalModels = async () => {
        if (!canonicalModelsData || canonicalModelsData?.length === 0) {
            await dispatch(getAllCanonicalModels());
            saveDataSources();
        }
    };

    useEffect(() => {
        fetchCanonicalModels();
        saveDataSources();
    }, []);

    const saveDataSources = () => {
        if (selectedDataSources?.length) {
            const updatedDataSources = selectedDataSources.map((dataSource: any) => {
                return {
                    ...dataSource,
                    selectedValue: dataSource?.commonKey,
                };
            });
            setDataSources(updatedDataSources);
        }
    };

    useEffect(() => {
        if (selectedDataSources?.length && canonicalModelsData?.length) {
            const updatedDataSources = selectedDataSources.map((dataSource: any) => {
                const matchingModel = canonicalModelsData.find((model: any) => model.id === dataSource.id);
                if (matchingModel) {
                    return {
                        ...dataSource,
                        attributes: Object.keys(matchingModel.schema.properties).map((key) => ({
                            name: key,
                            ...matchingModel.schema.properties[key],
                        })),
                        selectedValue: dataSource.commonKey || '',
                    };
                }
                return dataSource;
            });
            setDataSources(updatedDataSources);
        }
    }, [selectedDataSources, canonicalModelsData]);
    const handleBackButton = () => {
        dispatch(updateActiveStep(stepperRoutes.step_2));
    };

    const handleNextButton = () => {
        const mappedDataSources = dataSources.map((dataSource: any) => ({
            id: dataSource.id,
            commonKey: dataSource.selectedValue,
        }));
        const payload = {
            testSessionRequest: {
                dataSources: mappedDataSources,
                description: testSessionDescription,
                draftStatus: 'step_4',
                filterInfo: filterInfo,
                isDraft: true,
                name: testSessionName,
                testSessionTestCases: testSessionTestCases.filter((testCase: any) => testCase.testCaseId),
            },
            id: testSessionId,
        };
        dispatch(updateTestSession(payload));
    };

    const handleAttributeChange = (dataSource: any, data: any) => {
        const updatedDataSources = dataSources.map((ds: any) => {
            if (ds.name === dataSource.name) {
                return {
                    ...ds,
                    selectedValue: data,
                };
            }
            return ds;
        });
        setDataSources(updatedDataSources);
    };

    const renderData = () => {
        const arrows = dataSources.length - 1;
        const colWidth = Math.floor(100 / dataSources.length);

        return (
            <div className='nexus-row'>
                {dataSources.map((dataSource: any, index: number) => (
                    <React.Fragment key={index}>
                        <div className={`width-${colWidth} datasource-info`}>
                            <div className='datasource-label nexus-mb-1'>
                                {t('mapping.dataSourceLabel')} {dataSource.name}
                            </div>
                            <Select
                                options={getDropdownValues(dataSource.attributes)}
                                placeholder='Select Attribute'
                                customOnChange={(data: any) => handleAttributeChange(dataSource, data)}
                                selectedValue={dataSource?.selectedValue}
                            />
                        </div>
                        {index < arrows && (
                            <div className='right-arrow'>
                                <NexusIcon src={RightArrow} size='md' />
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        );
    };

    const handleNextState = () => {
        return !dataSources.every((dataSource: any) => dataSource.selectedValue !== '');
    };

    return (
        <div className='mapping-container' data-testid='mapping-component'>
            <NexusCard className='nexus-card-border-0 nexus-p-3'>
                <div className='nexus-row'>
                    <div className='circle nexus-ml-1'>1</div>
                    <div className='nexus-subtitle nexus-ml-1'>{t('mapping.header')}</div>
                </div>
                <div className='nexus-row nexus-caption-copy nexus-mt-1 nexus-ml-3 nexus-mb-2'>
                    {t('mapping.headerText')}
                </div>

                <div className='datasource-map'>
                    <div>{renderData()}</div>
                </div>

                <div className='nexus-flex-row-reverse nexus-mt-3'>
                    <div className='nexus-row'>
                        <div className='nexus-mr-2'>
                            <ButtonComponent
                                type={['nexus-btn nexus-rhythm-top-5']}
                                label={t('buttons.back')}
                                click={handleBackButton}
                            />
                        </div>
                        <div className='next-button-styles'>
                            <ButtonComponent
                                extraClass='nexus-btn-default'
                                type={['nexus-btn-primary nexus-rhythm-top-5']}
                                label={t('buttons.next')}
                                click={handleNextButton}
                                disabled={handleNextState()}
                            />
                        </div>
                    </div>
                </div>
            </NexusCard>
        </div>
    );
};
export default Mapping;
