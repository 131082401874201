import React, { useEffect, useState } from 'react';
import FileUploadComponent from 'app/components/nds/fileupload/fileUpload';
import { NexusIcon } from '@nexus/react';
import FileIcon from '@nexus/core/dist/assets/icons/action/ic_description_24px.svg';

const CustomFileUpload = ({ handleUpload, removeUpload, disabled }: any) => {
    const [queue, setQueue] = useState<Array<any>>([]);

    const returnFileQueue = (fileQueue: any) => {
        setQueue(fileQueue);
        handleUpload(fileQueue);
    };

    useEffect(() => {
        if (removeUpload) {
            setQueue([]);
        }
    }, [removeUpload]);

    const removeFileQueue = () => {
        setQueue([]);
    };
    return (
        <>
            {queue.length ? (
                <div className='file-details'>
                    {queue.map((file: any) => {
                        return (
                            <>
                                <NexusIcon src={FileIcon} size='md' />
                                <div className='file-text'>
                                    <span className='bold-text'>Name:</span> {file.name}
                                </div>
                                <div className='file-text'>
                                    <span className='bold-text'>Size:</span> {file.size}
                                </div>
                            </>
                        );
                    })}
                </div>
            ) : (
                <FileUploadComponent
                    accept='.csv'
                    returnFileQueue={returnFileQueue}
                    removeFileQueue={removeFileQueue}
                    classes={'custom-file-upload'}
                    infoMsg='Click or drop files to upload'
                    showSizeMsg={false}
                    disabled={disabled}
                />
            )}
        </>
    );
};

export default CustomFileUpload;
