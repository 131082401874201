import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import { DateFormat, EXPRESSION, MVEL, fileStatusFlag } from 'constants/constants';
import { NexusCard, NexusCardBody, NexusIcon, NexusNotification } from '@nexus/react';
import { useAppDispatch } from 'store';
import React, { useState, useEffect } from 'react';

import ApprovedIcon from '@nexus/core/dist/assets/icons/action/ic_check_circle_24px.svg';
import ButtonComponent from 'app/components/nds/button/button';
import CalendarIcon from '@nexus/core/dist/assets/icons/media/ic_today_24px.svg';
import DatePicker from 'react-date-picker';
import EditIcon from '@nexus/core/dist/assets/icons/action/ic_edit_24px.svg';
import ModalComponent from 'app/components/nds/modal/modal';
import Select from 'app/components/nds/select/select';
import { getHomeUrl } from 'utils/redirectionUtils';
import { stepperRoutes } from 'constants/stepperConstants';
import './confirmationComponent.scss';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getButtonIndex } from 'utils/generateButtonIndex';
import {
    getTestSession,
    getCanonicalModelById,
    getExpPreview,
    runTestSession,
    updateTestSession,
    updateActiveStep,
} from '../../redux/testSessionReducer';
import { currentTestSession } from '../../redux/testSessionSelectors';
import { useSelector } from 'react-redux';

const ConfirmationComponent = () => {
    const [t] = useTranslation('lang');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [intialPreviewCall, setIntialPreviewCall] = useState<boolean>(true); // to avoid multiple preview calls on initial load

    const schedulerOptions = [
        t('confirmationPage.dropdownValues.adHoc'),
        t('confirmationPage.dropdownValues.daily'),
        t('confirmationPage.dropdownValues.weekly'),
        t('confirmationPage.dropdownValues.monthly'),
        t('confirmationPage.dropdownValues.quarterly'),
        t('confirmationPage.dropdownValues.semiAnnually'),
        t('confirmationPage.dropdownValues.annually'),
    ];

    const schedulerDropdownValues = schedulerOptions.map((value) => ({ disabled: false, label: value, value: value }));
    const [dropdownValue, setDropdownValue] = useState<string>(t('dataSelection.pleaseSelect'));
    const [showPopUp, setShowPopUp] = useState<boolean>(false);
    const testSession = useSelector(currentTestSession);
    const {
        testSessionId,
        dateFrom,
        dateTo,
        testSessionTestCases,
        canonicalModelData: cannonicalModelData,
        uploadedDataSourceData,
    } = testSession;

    const canonicalModelId = testSessionTestCases?.[0]?.canonicalModelId;

    // initial preview call on load to get expression string
    useEffect(() => {
        if (intialPreviewCall && testSessionTestCases.length > 0 && canonicalModelId) {
            getPreviewExpression();
            setIntialPreviewCall(false);
        }
    }, [testSessionTestCases]);

    // to get the template details and the data source details
    useEffect(() => {
        if (canonicalModelId && (!cannonicalModelData || Object.keys(cannonicalModelData).length === 0)) {
            dispatch(getCanonicalModelById(canonicalModelId));
        }
    }, [canonicalModelId]);

    const handleDropDown = (value: string) => {
        setDropdownValue(value);
    };

    const handleBackButton = () => {
        dispatch(updateActiveStep(stepperRoutes.step_5));
    };

    const handleEdit = (routes: string) => {
        dispatch(updateActiveStep(routes));
    };

    const handleRunButton = () => {
        const payload = {
            testSessionRequest: {
                dataInputFileId: uploadedDataSourceData?.id || null,
                dateFrom,
                dateTo,
                draftStatus: undefined,
                isDraft: false,
                scheduler: {
                    frequency: 'once',
                },
            },
            id: testSessionId,
        };

        dispatch(updateTestSession(payload)).then((data: any) => {
            if (data?.payload?.status >= 200 && data?.payload?.status < 300) {
                dispatch(runTestSession(testSessionId)).then((value: any) => {
                    if (value?.payload?.status >= 200 && value?.payload?.status < 300) {
                        setShowPopUp(true);
                    }
                });
            }
        });
    };

    const goToHome = () => {
        setShowPopUp(false);
        const home = getHomeUrl();
        navigate(home);
    };
    const viewTest = async () => {
        await dispatch(getTestSession(testSessionId));
        navigate(`/test-session-details/${testSessionId}`);
    };
    const checkDisabledParams = () => {
        if (
            uploadedDataSourceData?.status === fileStatusFlag.invalid ||
            uploadedDataSourceData?.status === fileStatusFlag.uploaded ||
            uploadedDataSourceData?.status === fileStatusFlag.validation ||
            dropdownValue === t('dataSelection.pleaseSelect')
        ) {
            return true;
        }
        return false;
    };
    const getPreviewExpression = () => {
        for (let testCase of testSessionTestCases) {
            const { scriptConfiguration, testCaseParameters, testCasePreview } = testCase;
            if (scriptConfiguration?.type === MVEL) continue;
            if (testCasePreview !== undefined && testCasePreview !== '') continue;
            const payload = {
                canonicalModelId: testCase.canonicalModelId,
                scriptConfiguration: {
                    execution_parameters:
                        testCaseParameters?.execution_parameters || scriptConfiguration?.execution_parameters,
                    expression_object: scriptConfiguration?.expression_object,
                    type: EXPRESSION,
                    user_selected_columns: scriptConfiguration?.user_selected_columns,
                },
            };
            dispatch(getExpPreview({ testTemplatePreviewRequest: payload, testCaseId: testCase.testCaseId }));
        }
    };
    const confirmationPopUpData = (
        <>
            <div className='modal-content'>
                <NexusIcon src={ApprovedIcon} className='nexus-ml-1 edit-approved-icon-color' />
            </div>
            <div className={'nexus-subtitle modal-content'}>{t('confirmationPage.modal')}</div>
        </>
    );
    const eqButtonList = [
        {
            buttonDisabled: false,
            buttonStyles: ['nexus-btn nexus-rhythm-top-5'],
            clickButton: goToHome,
            label: t('buttons.goToHome'),
        },

        {
            buttonDisabled: false,
            buttonStyles: ['nexus-btn-primary nexus-rhythm-top-5 nexus-mx-3'],
            clickButton: viewTest,
            label: t('buttons.viewTest'),
        },
    ];
    // Modal Footer Data
    const modalFooterData = (
        <div className={`nexus-mt-2 align-delete-modal-btn`}>
            {eqButtonList?.map((data: any, _index: any) => {
                return (
                    <ButtonComponent
                        type={data?.buttonStyles}
                        label={data?.label}
                        disabled={data?.buttonDisabled}
                        click={data?.clickButton}
                        key={getButtonIndex(_index)}
                    />
                );
            })}
        </div>
    );
    return (
        <>
            <ModalComponent
                show={showPopUp}
                closeModal={goToHome}
                children={confirmationPopUpData}
                size='md'
                extraClass='header-alignment'
                footerContent={modalFooterData}
            />
            <div className='nexus-row' data-testid='confirmation-component'>
                <div className='nexus-col-lg-9 nexus-col-md-5 nexus-col-sm-4 nexus-col-xs-4 nexus-col-xl-9 nexus-mt-2'>
                    <NexusCard className='nexus-card-border-0 nexus-py-1 nexus-pl-2 card-height'>
                        <NexusCardBody>
                            <div className='nexus-mt-3 nexus-ml-1 confirmation-header'>
                                {t('confirmationPage.confirmationHeader')}
                            </div>
                            <div className='nexus-body-sm nexus-mt-1 nexus-ml-1'>
                                {t('confirmationPage.confirmationBody')}
                            </div>
                            <div className='nexus-mt-4 confirmation-border'></div>
                            <div className='nexus-mt-2'>
                                <div className='nexus-col-lg-12'>
                                    <div className='content-placement'>
                                        <span className='capital nexus-mt-1'>
                                            {t('confirmationPage.dataSelection')}
                                        </span>
                                        <NexusIcon
                                            src={EditIcon}
                                            className='nexus-ml-1 edit-icon-styles edit-icon-color nexus-mt-1'
                                            onClick={() => handleEdit(stepperRoutes.step_4)}
                                            data-testid='data-selection-icon'
                                        />
                                    </div>
                                    <div className='data-selection-info text'>
                                        {uploadedDataSourceData?.status === fileStatusFlag.ready ? (
                                            <div className='content-placement' data-testid='source-ready'>
                                                <NexusNotification variant='success' />
                                                {uploadedDataSourceData?.name}
                                            </div>
                                        ) : (
                                            cannonicalModelData?.tableName
                                        )}
                                        {uploadedDataSourceData?.status === fileStatusFlag?.invalid && (
                                            <div className='content-placement' data-testid='failed-message'>
                                                <NexusNotification variant='error' className='nexus-ml-1' />
                                                {t('dataSelection.validationFailedmessage')}
                                            </div>
                                        )}
                                        {uploadedDataSourceData?.status &&
                                            uploadedDataSourceData?.status !== fileStatusFlag?.invalid &&
                                            uploadedDataSourceData?.status !== fileStatusFlag?.ready && (
                                                <div
                                                    className='content-placement nexus-mt-1 inValidation'
                                                    data-testid='inValidation'
                                                >
                                                    <div className='loader-styles nexus-ml-1' />
                                                    <div className={'nexus-subtitle-sm nexus-ml-2 nexus-mt-2'}>
                                                        {t('dataSelection.validationInProgress')}
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className='nexus-col-lg-12 nexus-mt-4'>
                                <div className='nexus-row'>
                                    <div className='nexus-col-lg-3 align'>
                                        <div className='capital nexus-mt-1'>
                                            {t('confirmationPage.templateSelection')}
                                        </div>
                                        <NexusIcon
                                            src={EditIcon}
                                            className='nexus-ml-1 edit-icon-color'
                                            onClick={() => handleEdit(stepperRoutes.step_2)}
                                            data-testid='expression-icon'
                                        />
                                    </div>
                                    <div className='align'>
                                        <div className='nexus-mt-1 capital'>{t('expressionBuilder.header1')}</div>
                                        <NexusIcon
                                            src={EditIcon}
                                            className='nexus-ml-1 edit-icon-color'
                                            onClick={() => handleEdit(stepperRoutes.step_5)}
                                            data-testid='expression-icon'
                                        />
                                    </div>
                                    {testSessionTestCases?.map((data: any) => {
                                        const { scriptConfiguration, testCasePreview } = data;
                                        return (
                                            <div className='nexus-row nexus-col-lg-12'>
                                                <div className='nexus-body nexus-col-lg-3 nexus-mt-1 temp-selection-info'>
                                                    {data?.name}
                                                </div>
                                                <div className='nexus-body nexus-col-lg-9 nexus-mt-1 exp-builder-info'>
                                                    {scriptConfiguration?.type === EXPRESSION
                                                        ? testCasePreview
                                                        : scriptConfiguration?.expression_string}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </NexusCardBody>
                    </NexusCard>
                </div>
                <div className='nexus-col-lg-3 nexus-col-md-3 nexus-col-sm-4 nexus-col-xs-4 nexus-col-xl-3 nexus-mt-2'>
                    <NexusCard className='nexus-card-border-0 nexus-py-1 nexus-px-2 card-height'>
                        <NexusCardBody>
                            <div className='nexus-subtitle nexus-my-2'>{t('confirmationPage.testScheduler')}</div>
                            <div className='nexus-data nexus-mt-4 nexus-mb-1'>{t('confirmationPage.frequency')}</div>
                            <Select
                                selectedValue={dropdownValue}
                                options={schedulerDropdownValues}
                                customOnChange={handleDropDown}
                                disabledOption={t('dataSelection.pleaseSelect')}
                            />
                            <div className='nexus-mt-4'>
                                <div className='nexus-data'>{t('confirmationPage.startDate')}</div>
                                <DatePicker
                                    data-testid='input-textbox-date-from'
                                    format={process.env.REACT_APP_DATE_FORMAT}
                                    monthPlaceholder={DateFormat.monthPlaceholder}
                                    dayPlaceholder={DateFormat.dayPlaceholder}
                                    yearPlaceholder={DateFormat.yearPlaceholder}
                                    className='date-picker'
                                    disabled={true}
                                    calendarIcon={<NexusIcon src={CalendarIcon} size='sm' />}
                                />
                            </div>
                            <div className='nexus-mt-4 nexus-pb-1'>
                                <div className='nexus-data'>{t('confirmationPage.endDate')}</div>
                                <DatePicker
                                    data-testid='input-textbox-date-to'
                                    format={process.env.REACT_APP_DATE_FORMAT}
                                    monthPlaceholder={DateFormat.monthPlaceholder}
                                    dayPlaceholder={DateFormat.dayPlaceholder}
                                    yearPlaceholder={DateFormat.yearPlaceholder}
                                    className='date-picker'
                                    disabled={true}
                                    calendarIcon={<NexusIcon src={CalendarIcon} size='sm' />}
                                />
                            </div>
                        </NexusCardBody>
                    </NexusCard>
                </div>
            </div>
            <div className='nexus-flex-row-reverse nexus-mt-1'>
                <div className='nexus-row'>
                    <div className='nexus-mr-2'>
                        <ButtonComponent
                            type={['nexus-btn nexus-rhythm-top-5']}
                            label={t('buttons.back')}
                            disabled={false}
                            click={handleBackButton}
                        />
                    </div>
                    <div className='nexus-mr-1'>
                        <ButtonComponent
                            extraClass='nexus-btn-default'
                            type={['nexus-btn-primary nexus-rhythm-top-5']}
                            label={t('buttons.runTest')}
                            click={handleRunButton}
                            disabled={checkDisabledParams()}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default ConfirmationComponent;
